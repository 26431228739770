import dayjs from 'dayjs';
export const MONTH_ARRAY = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
export const DASHBOARD_OPTIONS = {
  app: 'App',
  stc: 'STC',
  employee: "Employee's",
  partner: "Partner's",
  cfa1_2024: 'CFA Level 1',
  cfa1_2025: 'CFA Level 1 2025 ',
  cfa2: 'CFA Level 2',
  cfa2_2025: 'CFA Level 2 2025 ',
  cfa3: 'CFA Level 3',
  cfa3_2025: 'CFA Level 3 2025 ',
  esg: 'ESG',
  frm1: 'FRM Part I',
  frm2: 'FRM Part II',
  soa_p: 'Actuarial Probability',
  soa_fm: 'Actuarial Financial Mathematics',
  soa_ifm: 'Actuarial Investment and Financial Markets',
  gmat: 'GMAT',
  gmat_focus: 'GMAT FOCUS',
  ea: 'EA',
  user_tracking: 'User Tracking',
};

export const EXAM_MAPPING = {
  cfa: 'CFA Level 1',
  cfa_2: 'CFA Level 2',
  cfa_2022: 'CFA Level 1 2022',
  cfa_2025: 'CFA Level 1 2025',
  cfa_2023: 'CFA Level 1 2023',
  cfa_2024: 'CFA Level 1 2024',
  esg: 'ESG',
  gmat_focus: 'GMAT FOCUS',
  cfa_2_2022: 'CFA Level 2 2022',
  cfa_2_2023: 'CFA Level 2 2023',
  cfa_2_2025: 'CFA Level 2 2025',
  cfa_3: 'CFA Level 3',
  cfa_3_2025: 'CFA Level 3 2025',
  frm: 'FRM Part I & II',
  soa_p: 'Actuarial- P',
  soa_fm: 'Actuarial- FM',
  soa_ifm: 'Actuarial- IFM',
  gmat: 'GMAT',
  ea: 'EA',
};

//* THIS DROPDOWN OPTION USE FOR LAST MONTH COMPONENT WHICH WHY USE OLDER EXAM NAME
export const examOptions = [
  { value: '', label: 'Select Exam' },
  { value: 'cfa', label: 'CFA Level 1' },
  { value: 'cfa_2', label: 'CFA Level 2' },
  { value: 'cfa_3', label: 'CFA Level 3' },
  { value: 'esg', label: 'ESG' },
  { value: 'frm', label: 'FRM Part I' },
  { value: 'frm_2', label: 'FRM Part II' },
  { value: 'soa_p', label: 'Actuarial-P' },
  { value: 'soa_fm', label: 'Actuarial-FM' },
  { value: 'gmat_focus', label: 'GMAT FOCUS' },
  { value: 'ea', label: 'EA' },
];
export const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
//* CREATED NEW { MOMENTS } KEY'S STARTED HERE ACCORDING TO DAY JS
// export const defaultYearFormat = 'YYYY';
// export const currentYear = parseInt(dayjs().format(defaultYearFormat));
// export const dbAcceptableFormat = 'YYYY-MM-DD';
// export const defaultDatePickerFormat = 'yyyy-MM-dd';
// export const defaultCreatedDateFormat = 'LL';
// export const defaultCreatedDateFormatDayjs = 'MMMM D, YYYY ';

// export const standardDateTimeFormat = 'lll';
// export const todayDate = dayjs().format('YYYY-MM-DD');

//* CREATED NEW { DAY-JS } KEY'S STARTED HERE ACCORDING TO DAY JS
export const defaultYearFormat = 'YYYY';
export const currentYear = dayjs().format('YYYY'); // No need to parse or format the integer.
export const dbAcceptableFormat = 'YYYY-MM-DD';
export const defaultDatePickerFormat = 'YYYY-MM-DD'; // Updated for Day.js.
export const defaultCreatedDateFormat = 'MMMM D, YYYY'; // Updated for Day.js.
export const standardDateTimeFormat = 'MMM D, YYYY h:mm A'; // Format it manually.
export const todayDate = dayjs().format('YYYY-MM-DD');
export const SecondsToHHMMSS = sec => {
  const seconds = sec % 60;
  const minutes = Math.floor(sec / 60);
  const hours = Math.floor(sec / 3600);
  return `${hours}:${minutes}:${seconds}`;
};
export const appDomainUrl = 'https://app.analystprep.com';

// validation maxlength QUESTION
export const NAME_MAXLENGTH = 500;
export const QUESTION_CHOICE_MAXLENGTH = 500;
// validation maxlength NOTES
export const NOTES_NAME_MAXLENGTH = 500;
export const NOTES_POST_TITLE = 500;
// validation maxlength video
export const VIDEO_TITLE_MAXLENGTH = 500;
export const examObject = {
  'CFA Level 1': 'cfa',
  'CFA Level 1 2022': 'cfa_2022',
  'CFA Level 1 2023': 'cfa_2023',
  'CFA Level 2': 'cfa_2',
  'CFA Level 2 2022': 'cfa_2_2022',
  'CFA Level 2 2023': 'cfa_2_2023',
  'CFA Level 3': 'cfa_3',
};
export const CBTMockTableDataKey = [
  'id',
  'title',
  'url_id',
  'url_id_qq',
  'video_order',
  'mock_id',
  'free',
  'isActive',
];

export const statusOption = [
  { value: 1, label: 'Active', name: 'isActive' },
  { value: 0, label: 'Inactive', name: 'isActive' },
];
export const questionType = [
  { value: '1', label: 'Free', name: 'free' },
  { value: '0', label: 'Premium', name: 'free' },
];

export const questionRightChoice = [
  { value: 'choice_a', label: 'Choice A', name: 'right_choice' },
  { value: 'choice_b', label: 'Choice B', name: 'right_choice' },
  { value: 'choice_c', label: 'Choice C', name: 'right_choice' },
];
export const questionOrderOption = [
  { value: '1', label: '1', name: 'order_questions' },
  { value: '0', label: '0', name: 'order_questions' },
];

export const getChapterAPIEndpoint = {
  cfa_3: 'GET_ALL_CFA3_CHAPTERS',
  soa_p: 'GET_ALL_PROBABILITY_CHAPTERS',
};

export const DEFAULT_YEAR = '2024';

export const adminRoles = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  partner: 'partner',
  partnerManager: 'partnerManager',
};
export const mockTableData = {
  sessions: [
    { label: '#' },
    { label: 'Session Name' },
    { label: 'Created Date' },
    { label: 'Action' },
  ],
  pathway: [
    { label: '#' },
    { label: 'Pathway Name' },
    { label: 'Created Date' },
    { label: 'Action' },
  ],
  default: [
    { label: '#' },
    { label: 'Mock Id' },
    { label: 'Mock Name' },
    { label: 'PDF' },
    { label: 'Created Date' },
    { label: 'Action' },
  ],
};
export const PATHWAY_ROUTE = '/pathway';
export const SESSION_ROUTE = '/sessions';
export const ESSAY_ROUTE = '/essay';
export const MOCK_ROUTE = '/mock';

export const MESSAGES = {
         CASE_ACTIVATE: 'Do you want to activate this case',
         MANAGER_CREATED: 'Manager created successfully',
         TEAM_MEMBER_CREATED: 'Team Member created successfully',
         MANAGER_UPDATED: 'Manager updated successfully',
         TEAM_MEMBER_UPDATED: 'Team Member updated successfully',
         MANAGER_DELETED: 'Manager deleted successfully',
         TEAM_MEMBER_DELETED: 'Team Member deleted successfully',
         MANAGER_STATUS_UPDATE: 'Manager status update successfully',
         TEAM_MEMBER_STATUS_UPDATE: 'Team Member status update successfully',
         SOMETHING_WRONG: 'Something went wrong..',
         UNKNOWN_ERROR: 'Unknown error',
         CASE_DEACTIVATE: 'Do you want to deactivate this case',
         COMPUTER_BASED_TESTING: ' Computer-Based Testing ',
         COMPUTER_TESTING_SESSION: 'Sessions',
         TOTAL_MANAGER: 'Total Manager(s)',
         TOTAL_TEAM_MEMBER: 'Total Team Member(s)',
         COMPUTER_TESTING_PATHWAY: 'Pathway',
         COMPUTER_TESTING_MOCK_EXAMS: 'Mock Exams',
         CFA3_ESSAY_VIDEOS: 'CFA 3 Essay Cases Videos',
         CFA3_VIDEOS: 'CFA 3 Videos',
         CFA3_VIEW_ESSAY_VIDEOS: ' CFA3 View Essay Cases Videos',
         CFA3_VIEW_VIDEOS: ' CFA3 Videos View',
         CFA3_EDIT_ESSAY_VIDEOS: 'CFA 3 Essay Cases Edit Video',
         CFA3_EDIT_VIDEOS: 'CFA 3 Edit Video',
         CFA3_ADD_ESSAY_VIDEOS: 'CFA 3 Add New Essay Cases Video',
         CFA3_ADD_VIDEOS: 'CFA 3 Add New Video',
         CFA3_2025_ESSAY_CASE_QUESTIONS: 'cfa3_2025_essay_case_questions',
         CFA3_ESSAY_CASE_QUESTIONS: 'cfa3_essay_case_question',
         CFA3_2025_QUESTIONS: 'cfa3_2025_question',
         CFA3_QUESTIONS: 'cfa3_question',
         EXPLANATION: 'explanation',
         DEVELOPMENT_JUSTIFICATIONS: 'development_justification',
         USER_LOGIN_DETAILS: 'Registration Details',
         EMAIL_USERNAME_EXIST: 'Email or Username already exists',
         EMAIL_ALREADY_EXIST: 'Email already exists',
         managerDeleteConfirmation: isSuperAdmin => ({
           title: 'Are you sure?',
           text: `Do you want to delete this ${
             isSuperAdmin ? 'Manager' : 'Team Member'
           }`,
         }),
       };
export const reportsExams = {
  CFA_3_2025: 'CFA Level 3 2025',
  CFA_3: 'CFA Level 3',
};

export const tableWiseAttributes = {
  tab_question: {
    tableName: 'gmat_focus_tab',
    answer: 'explanation',
    choiceName: 'statement',
  },
  table_question: {
    tableName: 'gmat_focus_table_analyse',
    answer: 'explanation',
    choiceName: 'option',
  },
  two_part_question: {
    tableName: 'gmat_focus_two_part_question',
    answer: 'explanation',
    choiceName: 'option',
  },
  graph_question: {
    tableName: 'gmat_focus_graphical_multi_statement',
    answer: 'explanation',
    choiceName: 'statement',
  },
};
export const COUNT_KEYS = {
  question: {
    attempt: 'attemptedQuestionCount',
  },
  notes: {
    attempt: 'attemptedNotesCount',
  },
  cbt: {
    attempt: 'attemptedCBTQuestionCount',
  },
};
export const STUDENT_PROGRESS_FILTER_FIELDS = [
  {
    name: 'selectedExam',
    type: 'dropdown',
    options: [
      { value: 'cfa1_2025', label: 'CFA I' },
      { value: 'cfa2_2025', label: 'CFA II' },
      { value: 'cfa3_2025', label: 'CFA III' },
      { value: 'esg', label: 'ESG' },
      { value: 'frm', label: 'FRM Part I & II' },
      { value: 'soa_p', label: 'Actuarial - P' },
      { value: 'soa_fm', label: 'Actuarial - FM' },
      { value: 'gmat_focus', label: 'GMAT Focus' },
      { value: 'ea', label: 'Executive Assessment' },
    ],
    defaultValue: { value: 'cfa1_2025', label: 'CFA I' },
    placeholder: 'Select Exam',
  },
  {
    name: 'selectedDays',
    type: 'dropdown',
    options: [
      { value: 7, label: '7 Days' },
      { value: 30, label: '30 Days' },
      { value: 90, label: '90 Days' },
    ],
    placeholder: 'Select Days',
    defaultValue: { value: 30, label: '30 Days' },
  },
  {
    name: 'partnerId',
    type: 'dropdown',
    placeholder: 'All Partners',
  },
  {
    name: 'userId',
    type: 'dropdown',
    placeholder: 'All Users',
  },
];

export const MANAGER_FILTER_FIELDS = [
  {
    name: 'partnerId',
    type: 'dropdown',
    placeholder: 'All Partners',
  },
  {
    name: 'managerId',
    type: 'asyncDropdown',
    placeholder: 'All Managers',
  },
  {
    name: 'status',
    type: 'dropdown',
    options: [
      { value: '1', label: 'Active' },
      { value: '0', label: 'In-active' },
    ],
    placeholder: 'Select Status',
  },
];

export const IDS = {
  partner: 'partnerId',
  user: 'userId',
  manager: 'managerId',
};

export const DEFAULT_MANAGER_STATE = {
  id: '',
  managerEmail: '',
  managerName: '',
  partnerId: '',
  loader: false,
};

export const CATEGORIES = {
  question: 'question',
  notes: 'notes',
  cbt: 'cbt',
};

export const TABLE_STRUCTURE_DETAIL = {
  manager: {
    columns: [
      '#',
      'Manager Name',
      'Email',
      'Partner Name',
      'Created Date',
      'Status',
      'Action',
    ],
    dataKey: ['id', 'name', 'email', 'partner.partnerName', 'createdAt', 'isActive'],
  },
};

export const LICENSING_TYPE_OPTIONS = [
  { value: 'partner_billed', label: 'Partner Billed - Monthly' },
  { value: 'user_billed', label: 'User Billed - Preferred Pricing' },
];
export const LICENSING_NAME = {
  partner_billed: 'Partner Billed',
  user_billed: 'User Billed',
};
export const contentTypes = ['notes', 'question', 'cbt'];


export const updateQueryParams = (history, queryData) => {
  const queryParams = new URLSearchParams(queryData).toString();
  history.push(`?${queryParams}`);
};
const COMMON_EXAM_CATEGORY = [
  { value: 'esg', label: 'ESG' },
  { value: 'frm1', label: 'FRM Part I' },
  { value: 'frm2', label: 'FRM Part II' },
  { value: 'soa_p', label: 'Actuarial - P' },
  { value: 'soa_fm', label: 'Actuarial - FM' },
  { value: 'gmat_focus', label: 'GMAT FOCUS' },
  { value: 'ea', label: 'EA' },
];

const COMMON_CATEGORIES = [
  { value: 'cfa1_2025', label: 'CFA Level 1' },
  { value: 'cfa2_2025', label: 'CFA Level 2' },
  { value: 'cfa3_2025', label: 'CFA Level 3' },
];

export const SUPER_ADMIN_DASHBOARD_CATEGORIES = [
  { value: 'app', label: 'App' },
  { value: 'stc', label: 'STC' },
  { value: 'employee', label: 'Employee' },
  { value: 'partner', label: 'Partner' },
  ...COMMON_CATEGORIES,
  ...COMMON_EXAM_CATEGORY,
];

export const OTHER_ROLE_DASHBOARD_CATEGORIES = [
  { value: 'employee', label: 'Dashboard' },
  { value: 'gmat', label: 'GMAT' },
  ...COMMON_CATEGORIES,
  ...COMMON_EXAM_CATEGORY,
];

export const EXAM_OPTIONS = [
  { value: '', label: 'Select Exam' },
  { value: 'cfa_2025', label: 'CFA Level 1' },
  { value: 'cfa_2_2025', label: 'CFA Level 2' },
  { value: 'cfa_3_2025', label: 'CFA Level 3' },
  ...COMMON_EXAM_CATEGORY,
];

export const LICENSING_TYPE = {
  PARTNER_BILLED: 'partner_billed',
  USER_BILLED: 'user_billed',
};
